import React, { useState } from 'react';

export default function Accordion({ title, content, type }) {

    const [isActive, setIsActive] = useState(false);
    // const [page, setPage] = useState(0);

    // const handleNext = () => {
    //     if((page+1) !== content.length){
    //         setPage(page + 1);
    //     }
    // }

    // const handlePrev = () => {
    //     if((page) !== 0) {
    //         setPage(page - 1);
    //     }
    // }

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <div>{ title }</div>
                <div><span className="accordion-toggler">{ isActive ? '-': '+' }</span></div>
            </div>

            <div className={ isActive ? "accordion-item-show__on" : "accordion-item-show" }>
                
                { isActive && content ? <div>
                    
                    {content.map(item => (
                    <div className="accordion-content" 
                        key={type === "annual_report" ? item.caption : item.file.id}>
                        <a className="accordion-content-item" 
                            href={type === "annual_report" ? item.file.url : item.file.mediaItemUrl} target="_blank" rel="noreferrer">
                            <div>{ item.title }</div>
                            <div>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1667px-PDF_file_icon.svg.png" 
                                    height="20px" alt="pdf"/>
                                </div>
                        </a>
                    </div>
                    ))}
                    {/* <div className="pagination-section">
                        <div>
                            <button onClick={handlePrev}>Prev</button>
                            <button onClick={handleNext}>Next</button>
                        </div>
                        <span>page {page+1} of {content.length}</span>
                    </div> */}
                    </div> : isActive && !content ? "Coming soon": null }
            </div>
        </div>
    )
}