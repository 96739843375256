import React, { useState, useEffect } from 'react';

import axios from 'axios';

export default function LiquidityPricing() {

    const [iclfPricingData, setIclfPricingData] = useState({});
    let date = new Date();
    date.setDate(date.getDate() - 1); // get previous working date
    if (new Date().getDay() == 0) {
        date.setDate(date.getDate() - 1);
    } else if (new Date().getDay() == 1) {
        date.setDate(date.getDate() - 2);
    }
    let value_date = ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();

    useEffect(
        () => {
            let url_from_dms = `https://instructor.icassetmanagers.com/php/apisetup/api/iclf_pricing.php?value_date=${value_date}`;
            let url = `https://wealth.ic.africa/api/funds/iclf`;
            axios.get(url)
                .then(res => {
                    setIclfPricingData(res.data.data);
                })
                .catch(error => console.log(error));
        }
        , [value_date]);

    return (
        <>
            <section className="iclf-pricing pt-4">
                <div className="page__iclf-pricing-table-section" align="center">
                    <table className="table" style={{ width: '70vw' }}>
                        <thead>
                            <tr>
                                <th colSpan="3" style={{ textAlign: 'left' }}><strong>IC Liquidity Fund Plc Current Unit Price</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><strong>Last Updated</strong></td>
                                <td style={{ lineHeight: 3 }}><strong>Unit Price (GHS)</strong></td>
                                <td><strong>Annualized YTD Performance (%)</strong></td>
                                {/* <td><strong>Y/Y (%)</strong></td> */}
                            </tr>
                            <tr>
                                <td>{iclfPricingData.value_date ? iclfPricingData.value_date : value_date}</td>
                                <td>{iclfPricingData.unit_price && parseFloat(iclfPricingData.unit_price).toFixed(4)}</td>
                                <td>{iclfPricingData.year_to_year_performance && parseFloat(iclfPricingData.year_to_year_performance * 100).toFixed(2)}</td>
                                {/* <td>--</td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}