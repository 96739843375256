import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import LiquidityPricing from "../components/liquidityPricing";
import HistoricalDocuments from "../components/iclf/historicalDocuments";

export default function Liquidity({ data }) {
  const page = data.page;
  
  const factsheets = page.factsheets.factsheets;
  const factsheets_2022 = [];
  const factsheets_2023 = [];
  const factsheets_2024 = [];

  for (let element of factsheets) {
    if(element.caption.includes("fact-sheet-2022")) {
      factsheets_2022.push(element)
    } else if(element.caption.includes("fact-sheet-2023")) {
      factsheets_2023.push(element)
    } else if(element.caption.includes("fact-sheet-2024")) {
      factsheets_2024.push(element)
    }
  }

  const factsheets_data = {
    "2022": factsheets_2022,
    "2023": factsheets_2023,
    "2024": factsheets_2024
  }


  // const paginated_factsheets = [];
  // paginated_factsheets[0] = [];

  // const annual_reports = page.iclfAnnualReports.annualReports.reverse();
  // const paginated_annual_reports = [];
  // paginated_annual_reports[0] = [];

  // var cursor = 0;
  // for (let index = 0; index < factsheets.length; index++) {
  //   const element = factsheets[index];
  //   if((index !== 0) && (index % 10 === 0)) {
  //     cursor ++;
  //     paginated_factsheets[cursor] = []
  //   }
  //   paginated_factsheets[cursor].push(element)
  // }

  // var annual_report_cursor = 0;
  // for (let index = 0; index < annual_reports.length; index++) {
  //   const element = annual_reports[index];
  //   if((index !== 0) && (index % 10 === 0)) {
  //     annual_report_cursor ++;
  //     paginated_annual_reports[annual_report_cursor] = []
  //   }
  //   paginated_annual_reports[annual_report_cursor].push(element)
  // }


  return (
    <Layout>
      <Seo seo={page.seo} />
      <div className="page__featured-image__wrapper ">
        <img
          className="page__featured-image"
          src={page?.featuredImage?.node?.sourceUrl}
          alt=""
        />
        <h2 className="page__title">{page.title}</h2>
      </div>
      <div className="wysiwyg__content__wrapper pt-6 pb-6">
        <LiquidityPricing />
        <HistoricalDocuments data={factsheets_data} 
          annualReports={page.iclfAnnualReports.annualReports.reverse()}/>
        <div
          className="wysiwyg__content"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </div>
      <div className="mb-20" />
    </Layout>
  );
}

export const query = graphql`
  {
    page: wpPage(slug: { eq: "ic-liquidity" }) {
      title
      featuredImage {
        node {
          sourceUrl
        }
      }
      content
      seo {
        fullHead
      }
      factsheets {
        factsheets {
          caption
          title
          file {
            id
            mediaItemUrl
          }
        }
      }
      iclfAnnualReports {
        annualReports {
          caption
          title
          file {
            title
            url
          }
        }
      }
    }
    historicalFactSheets: allWpMediaItem(
      filter: {slug: {eq: "fact-sheet-2022-11"}}
      sort: {order: DESC, fields: slug}
      ) {
        edges {
          node {
            id
            title
            uri
            caption
            description
            mediaItemUrl
          }
      }
    }
  }
`;

// filter: {slug: {regex: "/fact-sheet/"}}
